import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-productinfo',
  templateUrl: './productinfo.component.html',
  styleUrls: ['./productinfo.component.scss'],
})
export class ProductinfoComponent implements OnInit {

  @Input() info = [];

  constructor() { }

  ngOnInit() {}

}
