import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {VendorsService} from '../../services/vendors/vendors.service';

@Component({
  selector: 'app-groupproducts',
  templateUrl: './groupproducts.component.html',
  styleUrls: ['./groupproducts.component.scss'],
})
export class GroupproductsComponent implements OnInit {

  constructor(
      private domSanitizer: DomSanitizer,
      private vendorsService: VendorsService
  ) { }

  ngOnInit() {
    // Cjw Test Company 1
    this.vendorsService.getAllVendorGroupProductsForUser('fc311260-75cb-11ef-9a40-f11bf7e2666f').then(result => {
      console.log('GROUPPRODUCTS', result)
    });
  }

}
