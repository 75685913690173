import { Injectable } from '@angular/core';
import {HttpEvent, HttpRequest, HttpInterceptor, HttpHandler, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ToastComponent} from '../components/toast/toast.component';

@Injectable({
    providedIn: 'root'
})
export class CachingInterceptor implements HttpInterceptor {
    constructor(private toast: ToastComponent) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                this.toast.presentError(error);
                return throwError(() => error as unknown as HttpEvent<any>);
            })
        );
    }
}
